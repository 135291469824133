.slider-box-shadow {
	.box-shadow(inset 0px 20px 30px -10px rgba(50, 50, 50, 0.6));
} 

.slider {
	@slider-height: 660px;
	@slider-padding-bottom: 220px;
	@control-width: 50px;
	
	background: @piros url("@{images-url}bg-header.jpg") 50% 50% no-repeat;
	background-size: cover;
	
	@media (min-width: 1600px){
		background-size: auto;
		position: relative;
		
		&:before, &:after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			width: 20%;
		}
		
		&:before {
			#gradient > .horizontal(@piros, transparent);
			left: 50%;
			margin-left: -800px;
		}
		
		&:after {
			#gradient > .horizontal(transparent, @piros);
			right: 50%;
			margin-right: -800px;
		}
	}
	
	.container {
		position: relative;
		
		.login-info {
			top: 0;
			z-index: 9000;
			
			@media (max-width: @screen-sm-max){
				position: static;
				margin-left: -@grid-gutter-width / 2;
				margin-right: -@grid-gutter-width / 2;
				text-align: right;
			}
		}
	}
	
	@media (min-width: @screen-md-min){
		height: @slider-height;
		padding-bottom: @slider-padding-bottom;
		
		+* {
			margin-top: -@slider-padding-bottom;
		}
		
		.carousel {
			padding-top: @control-width + 60 !important;
		}
	}
	
	@media (max-width: @screen-xs-max){
		height: unit(@slider-height / @container-sm * 40, vw);
	}
	
	.carousel {
		padding-top: @control-width;
	}
	
	.carousel-control {
		.square(@control-width);
		.slider-box-shadow;
		background: @piros;
		line-height: @control-width;
		
		&.left {
			border-bottom-left-radius: @control-width;
			padding-right: @grid-gutter-width / 2;
			margin-left: -@control-width;
			left: 50%;
			.text-right;
		}
		
		&.right {
			border-bottom-right-radius: @control-width;
			padding-left: @grid-gutter-width / 2;
			margin-right: -@control-width;
			right: 50%;
			.text-left;
		}
		
		&:hover, &:focus {
			.opacity(1);
			
			.glyphicon {
				.opacity(.4);
			}
		}
	}
	
	.carousel-caption {
		position: static;
		left: auto;
		right: auto;
		.text-left;
		
		h1 {
			.font-black;
			font-size: 2.66em;
			text-transform: uppercase;
			margin: 0;
			color: @white;
			
			&:after {
				content: '';
				display: block;
				width: 25%;
				border-bottom: 1px solid @white;
				margin: .5em 0;
			}
		}
		
		.caption-text {
			font-size: 1.5em;
			.font-thin;
		}
	}
}