//
// Pagination (multiple pages)
// --------------------------------------------------
.pagination {

  > li {
    display: inline; // Remove list-style and block-level defaults
    > a,
    > span {
      line-height: @line-height-computed;
	  .font-normal;
    }

	> a {
		border: 0 none;
		padding-top: 0;
		padding-bottom: 0;
	}

	  +li>a {
		  border-left: 1px solid @hr-border;
	  }
  }

  > .active > a,
  > .active > span {
    &,
    &:hover,
    &:focus {
	  .font-black;
    }
  }

}
