.media-heading {
	.open-sans-cimsor;
	font-size: 24px;
	text-transform: none;
	
	a, a:active, a:visited {
		color: @gray;
	}
	
	a:hover {
		color: @piros;
	}
}
