.open-sans-cimsor {
	font-family: @font-family-open-sans;
	font-weight: 800;
}

.page-header {
	border-bottom: 0 none;
	padding-bottom: 0;
	margin-top: 0;
	
	h1 {
		line-height: @line-height-base;
		color: @gray;
		margin-top: 0;
		.font-normal;
	}
}

// Description Lists
dt,dd {
	padding-top: .4em;
	margin-top: .4em;
}
dt {
}

// Horizontal description lists
//
// Defaults to being stacked without any of the below styles applied, until the
// grid breakpoint is reached (default of ~768px).

.dl-horizontal {
  dd {
    &:extend(.clearfix all); // Clear the floated `dt` if an empty `dd` is present
  }

  @media (min-width: @grid-float-breakpoint) {
	dt {
	  width: @dl-horizontal-offset;
	  text-align: left;
	  .font-normal;
	  
	  &:first-child+dd {
		  background: none;
	  }
	}
	dd {
	  margin-left: @dl-horizontal-offset;
	}
	
	dt,dd {
		&:not(:first-child) {
			background: @lista-border 0 0 repeat-x;
		}
	}
  }
}