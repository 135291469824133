//
// Breadcrumbs
// --------------------------------------------------


.breadcrumb {
  font-size: @font-size-base;
  line-height: 2;
  margin-bottom: 0;
  margin-top: -2 * @font-size-base - @grid-gutter-width * 2;
  .font-normal;

  .breadcrumb-webshop & {
	  margin-top: 0;
  }

  li:last-child a {
	  color: @piros;
  }
  
  >li+li:before {
	  color: @text-color;
  }
  
  > .active {
    color: @breadcrumb-active-color;
  }
}
