body {
	background-image: url("@{images-url}bg.png");
	background-position: 50% -100px;
	background-repeat: no-repeat;
	.font-light;
}

.vertical-align {
	font-size: 0;

	&:before {
		content: '';
		display: inline-block;
		height: 100%;
		vertical-align: middle;
	}

	.vertical-align-middle {
		vertical-align: middle;
		display: inline-block;
		max-width: 100%;
	}

	.vertical-align-bottom {
		display: inline-block;
		max-width: 100%;
	}
}

.vertical-align-bottom {
	vertical-align: bottom;
}

.font-light {
	font-weight: 300;
}

.font-normal {
	font-weight: 400;
}

.font-semibold, .font-semi-bold {
	font-weight: 600;
}

.font-bold {
	font-weight: 700;
}

.bg-feher {
	background-color: @white;
}

.bg-piros {
	background-color: @piros;
}

.bg-majdnemszurke {
	background-color: @majdnemszurke;
}

.bg-balmenu {
	background-color: @webshop-balmenu;
}

.color-text {
	color: @text-color;
}

.color-piros {
	color: @piros;
}

.color-feher {
	color: @white;
}

.color-termek {
	color: @termekbetu;
}

.text-notransform {
	text-transform: none;
}

.text-underline {
	text-decoration: underline;
}

.cim {
	.open-sans-cimsor;
	font-size: 24px;
	color: @gray;
	text-transform: none;
}

.rolunk {
	@rolunk-height: 240px;
	
	background: url("@{images-url}bg-rolunk.jpg") 100% 50% no-repeat;
	background-size: cover;
	
	.lotos {
		background: url("@{images-url}lotos-rolunk.png") 100% 50% no-repeat;
		background-size: contain;
		.clearfix;
		height: @rolunk-height;
	}
	
	@media (min-width: @screen-md-min){
		height: @rolunk-height;
	}
}

#olajvalaszto-modal {
	svg {
		path.csepp {
			fill: @piros;
		}
	}
}

section.olajvalaszto {
	background: @white;
	.box-shadow(inset 0 10px 10px -5px #e5e5e5);
	margin-bottom: @grid-gutter-width * 6;

	.row {
		padding: @grid-gutter-width 0 0 0;
		margin: 0;
	}

	nav + & {
		margin-top: 0;
	}

	select {
		background: transparent;
		.text-uppercase;
		.font-normal;
	}
}

h1,h2,h3,h4,h5,h6 {
	color: @piros;
	.text-uppercase;
	.font-light;
}

hr {
	margin-top:    @grid-gutter-width * 1.5;
	margin-bottom: @grid-gutter-width * 1.5;
}

.btn {
	.button-size(@padding-base-vertical; 3em; @font-size-base; @line-height-base; @border-radius-base);
}

.btn-default {
	&:hover,
	  &:focus,
	  &.focus,
	  &:active,
	  &.active,
	  .open > .dropdown-toggle& {
		color: @white;
		background-color: @piros;
			border-color: @piros;
	  }
}

.szurkesav {
	background: #f4f4f4;
	padding: @grid-gutter-width 0;
	box-shadow: inset 0px 20px 40px -20px #ccc;
	margin-bottom: @grid-gutter-width * 2;
	
	.form-group {
		margin-bottom: 0;
	}
}

.form-kereses .btn-link {
	padding: @padding-base-vertical @padding-base-horizontal;
	border: 0 none;
}

.keresosav {
	margin-bottom: @grid-gutter-width;
	
	.form-group {
		margin-bottom: 0;
	}
	
	.kosar {
		.size(@container-md / @grid-columns - @grid-gutter-width, 45px + @grid-gutter-width);
		display: inline-block;
		margin-top: -@grid-gutter-width;
		background: @piros;
		color: @white;
		vertical-align: bottom;
		
		span {
			display: block;
			text-align: center;
			font-size: @font-size-large;
		}
		
		svg {
			display: block;
			margin: -12px auto 0 auto;
			
			path.kosar {
				fill: @white;
			}
		}
		
		@media (max-width: @screen-xs-max) {
			margin-top: 0;
			margin-bottom: -5px;
		}
	}
}

.termek {
	font-size: 14px;
	.font-normal;
	color: @termekbetu;
	
	.caption {
		border-top: 1px solid @hr-border;

		h3 {
			color: @black;
			.font-bold;
			font-size: @font-size-h4;
			margin: .2em 0;
		}
	}

	.akcio {
		position: absolute;
		right: 0;
		top: 0;
		padding: .2em .4em;
	}
	
	.size li+li:before {
		content: "/";
		padding-right: 5px;
		margin-left: -5px;
	}
	
	.popover {
		.hidden-sm;
	}
	
	.popover-title {
		border-bottom: 0 none;
		padding-bottom: 0;
		margin-bottom: .5em;
		color: @black;
		.font-bold;
	}
	
	.popover-content {
		padding-top: 0;
	}
}

.col-jobbarnyek {
	.box-shadow(8px 0px 20px -10px #777);
}

.termek-reszletek {
	&:before {
		#haromszog > .defaults();
		#haromszog > .jobbra();
		left: 100%;
	}
}

.termek.termekek {
	@termek-height: 390px;
	@kosar-height: 105px;
	
	.thumbnail {
		height: @termek-height;
		padding-bottom: @kosar-height;
		position: relative;
	}
	
	.arak {
		position: absolute;
		bottom: 0;
		right: 0;
		left: 0;
		height: @kosar-height;

		hr {
			margin: 10px 0 0 0;
		}
	}
}

.termek.adatlap {
	.page-header {
		margin-top: @grid-gutter-width;
		.text-center;
	}

	.akcio {
		position: static;
		display: inline-block;
	}
	
	.mennyiseg {
		margin-bottom: @grid-gutter-width;
	}
	
	.ar1 {
		font-size: 18px;
		margin-bottom: 0;
		margin-top: @grid-gutter-width * 2;
	}
	
	.ar2 {
		font-size: 22px;
		margin-bottom: @grid-gutter-width * 2;
	}

	h3 {
		.font-normal;
	}

	dd {
		.text-primary;
		.text-left;
		word-break: break-all;
	}
}

form.kapcsolat {
	margin-bottom: @grid-gutter-width * .75;
}

textarea[name=uzenet] {
	height: @input-height-base * 3 + @form-group-margin-bottom * 2;
}

input[name=kapcsolat_ok] {
	padding-left: 3em;
	padding-right: 3em;
	.text-uppercase;
}

.rendeles-menete {
	font-size: 14px;
	margin: @grid-gutter-width @grid-gutter-width / 2;

	>h4 {
		min-height: 24px;
		line-height: 24px;
		position: relative;
	}

	.text-muted {
		color: darken(@text-muted, 20%);
	}

	.active:before {
		#haromszog > .defaults();
		#haromszog > .jobbra();
		z-index: 100;
		top: 0;
		left: -@grid-gutter-width;
	}
}

.kosaroldal>h1 {
	margin-top: 0;
	padding-top: @grid-gutter-width;
}

.table-kosar {
	&, th, td {
		font-size: 14px;
		line-height: @line-height-base;
	}
	
	>thead>tr>th {
		border-bottom: 0 none;
	}
	
	>tbody, >head, >tfoot {
		>tr {
			>td, >th {
				vertical-align: middle;
				border-top: 1px solid @gray-lighter;
			}
		}
	}
	
	.kosarspinner {
		width: 35px;
	}

	.ar {
		.font-bold;
	}
}

.ui-widget {
	&, input, select, textarea, button {
		font-family: @font-family-base;
	}
}

.ui-spinner {
	@spinner-height: 28px;
	
	height: @spinner-height;
	border-radius: 0;
	
	.ui-spinner-button {
		.glyphicon;
		color: @gray;
		background: @white;
		display: block !important;
		position: absolute;
		right: 0;
		height: @spinner-height / 2;
		line-height: @spinner-height / 2;
		border-left: 1px solid @gray-lighter;
		
		&.ui-spinner-up {
			.glyphicon-triangle-top;
			top: 0;
		}
		
		&.ui-spinner-down {
			.glyphicon-triangle-bottom;
			top: @spinner-height / 2;
		}
		
		span {
			display: none;
		}
	}
}

#payment-card {
	~.kartyainfo {
		overflow: hidden;
		max-height: 0;
		margin: 0;
		padding: 0;
		.transition-duration(.2s);
		.transition-timing-function(cubic-bezier(0, 1, 0.5, 1));
	}
	
	&:checked ~ .kartyainfo {
		overflow: hidden;
		max-height: 1000px;
		.transition-duration(.2s);
		.transition-timing-function(ease-in);
	}
}

.table-attekintes {
	td.img {
		width: 60px;
		
		@media (max-width: @screen-xs-max) {
			+td {
				text-indent: -60px;
			}
		}
	}
	
	img {
		max-width: 60px;
		max-height: 60px;
	}
	
	td {
		vertical-align: middle !important;
	}
}

body>header {
	@header-height: 260px;
	
	.bg {
		height: @header-height;
		background: @piros url("@{images-url}bg-header.jpg") 50% 22% no-repeat;
		background-size: cover;

		@media (min-width: 1600px){
			background-size: auto;
			position: relative;

			&:before, &:after {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				bottom: 0;
				width: 20%;
			}

			&:before {
				#gradient > .horizontal(@piros, transparent);
				left: 50%;
				margin-left: -800px;
			}

			&:after {
				#gradient > .horizontal(transparent, @piros);
				right: 50%;
				margin-right: -800px;
			}
		}
	}
	
	.container {
		position: relative;
	}
}	

.login-info {
	position: relative;
	
	a {
		padding: @grid-gutter-width / 2 0;
		display: inline-block;
		
		&+a:before {
			margin: 0 @grid-gutter-width / 2;
			content: "\00b7";
		}
	}
}

#balmenu {
	.font-normal;

	.active {
		color: @piros !important;
	}
	
	.list-group-submenu {
		padding-left: 1em;
	}
}

footer {
	.bg-piros;
	color: @white;
	padding: @grid-gutter-width * 1.5;
	margin-bottom: @grid-gutter-width * 1.5;

	p {
		margin: 0;
		font-size: @font-size-small;
	}
}

time {
	color: @gray-light2;
	margin-bottom: .5em;
	display: inline-block;
}

.hir {
	&+& {
		margin-top: @grid-gutter-width;
		
		&:before {
			margin-bottom: @grid-gutter-width;
			content: '';
			display: block;
			width: 50%;
			height: 1px;
			background: @gray-lighter;
		}
	}
}

article {
	.content-columns(2, @grid-gutter-width);

	&.kapcsolat {
		.content-columns(1);
	}

	@media(max-width: @screen-xs-max){
		.content-columns(1);
	}

	aside {
		h1, h2 {
			.color-text;
			.font-normal;
			.text-notransform;
			border-bottom: 1px solid @hr-border;
			padding-bottom: @grid-gutter-width / 2;
		}
	}
}

header.webshop {
	margin-top: -96px;
}

ul li {
	list-style-image: url("@{images-url}ul-item.png");
}

.fooldal {
	.kep {
		padding: 0;

		&:before {
			#haromszog > .defaults();
		}

		&:first-child:before {
			#haromszog > .balra();
		}

		&:last-child:before {
			#haromszog > .jobbra();
		}
	}
}


#haromszog {
	.defaults {
		content: '';
		display: block;
		position: absolute;
		top: @grid-gutter-width;
		border: @grid-gutter-width * .6 solid transparent;
		width: @grid-gutter-width * .5;
		height: @grid-gutter-width;
		box-sizing: border-box;
	}

	.balra {
		border-right: @grid-gutter-width * .5 solid @white;
		right: 0;
	}

	.jobbra {
		border-left: @grid-gutter-width * .5 solid @white;
		left: 0;
	}
}

#rendezes {
	margin-top: @grid-gutter-width;

	.form-group {
		margin-bottom: 0;
	}
}