//
// Buttons
// --------------------------------------------------

.btn {
	.button-size(6px; 3em; 16px; 30px; @border-radius-base);
	.text-uppercase;
}

// Link buttons
// -------------------------

// Make a button look and behave like a link
.btn-link {
  color: @piros;
  .font-normal;
  font-size: @font-size-large;
  text-transform: uppercase;
  border-radius: 0;
  //padding: .5em 3em;

  &,
  &:active,
  &.active,
  &[disabled],
  fieldset[disabled] & {
    background-color: transparent;
    .box-shadow(none);
  }
  &,
  &:hover,
  &:focus,
  &:active {
    border-color: @gray-lighter;
  }
  &:hover,
  &:focus {
    color: @text-color;
    text-decoration: @link-hover-decoration;
    background-color: transparent;
  }
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus {
      color: @btn-link-disabled-color;
      text-decoration: none;
    }
  }
}

.btn-link-piros {
  	.btn-link;
	.font-semibold;
  
	&,
	&:focus,
	&:active,
	&:hover {
	  border-color: transparent;
	}
}
