//
// List groups
// --------------------------------------------------


// Linked list items
//
// Use anchor elements instead of `li`s or `div`s to create linked list items.
// Includes an extra `.active` modifier class for showing selected items.

.list-group-item a {
  color: @list-group-link-color;

  .list-group-item-heading {
    color: @list-group-link-heading-color;
  }

  // Hover state
  &:hover,
  &:focus {
    text-decoration: none;
    color: @list-group-link-hover-color;
    background-color: @list-group-hover-bg;
  }
  
  &[data-toggle=collapse]{
	  position: absolute;
	  right: 0;
	  top: 9px;
	  
	  @media (max-width: @grid-float-breakpoint-max) {
		  top: 5px;
		  font-size: 2em;
	  }

	  span:before {
		  content: "[+]";
	  }
  }
  
  &[aria-expanded=true] span:before {
	content: "[\2013]";
  }
}

.list-group-item {
	&, a {
		.text-uppercase;
		font-size: 14px;
	}
	
	.glyphicon {
		color: #ccc;
		.transition(transform .2s);
	}

  // Active class on item itself, not parent
  &.active,
  &.active:hover,
  &.active:focus {
    z-index: 2; // Place active items above their siblings for proper border styling
    color: @list-group-active-color;
    background-color: @list-group-active-bg;
    border-color: @list-group-active-border;

    // Force color to inherit for custom content
    .list-group-item-heading,
    .list-group-item-heading > small,
    .list-group-item-heading > .small {
      color: inherit;
    }
    .list-group-item-text {
      color: @list-group-active-text-color;
    }
  }
  
	@media (max-width: @grid-float-breakpoint-max) {
		border-top: 1px solid @gray-lighter;
	}
}

