.navbar {
	border: 0 none;
}

.navbar .brand img {
	margin-top: (@navbar-height - 41px) / 2;
	margin-left: @grid-gutter-width;
	
	@media (max-width: @grid-float-breakpoint-max) {
		margin-left: @grid-gutter-width / 2;
	}
}

.navbar-default {
	.navbar-nav {
		@media (min-width: @grid-float-breakpoint) {
			margin-left: @grid-gutter-width;
			
			.form-kereses {
				margin-top: (@navbar-height - @input-height-base) / 2;
			}
		}
		
		>li {
			.box-sizing(border-box);
			
			>a {
				font-size: @font-size-large;
				text-transform: uppercase;
				.font-normal;
			}
		}
		
		>li:hover a {
			color: @piros;
		}
	}
}

@media (max-width: @grid-float-breakpoint-max) {
	.navbar-nav {
		margin: 0;
		width: 100%;
		
		li {
			.text-right;
			
			&+li {
				border-top: 1px solid @gray-lighter;
			}
			
			a {
				font-size: 1.2em;
				line-height: 2em;
			}
		}
	}
}